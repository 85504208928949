import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import style from "./index.module.scss"

const IndexPage = () => {
  const content = useStaticQuery(graphql`
    query {
      home1: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home2: file(relativePath: { eq: "phonescreen_p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home3: file(relativePath: { eq: "home3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home4: file(relativePath: { eq: "home4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <MainLayout>
      <SEO title="Home" />
      <div className={style.welcome}>
        <div>
          <h2><span>We elevate our clients</span><span>We deliver results</span><span>We exceed expectations</span></h2>
        </div>
        <div>
          <Img fluid={content.home1.childImageSharp.fluid} />
        </div>
      </div>
      <div className={style.callout}>
        <div>
          <Img fluid={content.home3.childImageSharp.fluid} />
        </div>
        <div>
          <h3>About Us</h3>
          <p>Pitch Consulting is a boutique consulting agency based in Pittsburgh, PA, specializing in sponsorship, live entertainment, public relations, event design & production, and social media.</p>
          <Link to="/about" className={style.button}>
            Learn More
          </Link>
        </div>
      </div>
      <div className={style.calloutAlt}>
        <div>
          <h3>Our Work</h3>
          <p>Our passion lies in creating new and innovative ideas, and most importantly executing on every detail. We work with individuals, brands, corporations, and artists nationwide.</p>
          <Link to="/work" className={style.button}>
            View Gallery
          </Link>
        </div>
        <div>
          <Img fluid={content.home2.childImageSharp.fluid} />
        </div>
      </div>
      <div className={style.callout}>
        <div>
          <Img fluid={content.home4.childImageSharp.fluid} />
        </div>
        <div>
          <h3>Services</h3>
          <p>Whether you’re looking for a fresh event concept, sponsorship strategy, sales training for your team, social media management, or strategic partnerships to gain brand awareness, we have you covered.</p>
          <Link to="/services" className={style.button}>
            Learn More
          </Link>
        </div>
      </div>
      <div className={style.testimonials}>
        <h3>Testimonials</h3>
        <div>
          <div>
            <blockquote>
              &ldquo;Melinda and her team bring out the best in her clients and help them achieve what they didn't realize they could.&rdquo;
              <footer>
                — <cite className="author">Barrie Athol / CEO</cite>
              </footer>
            </blockquote>
          </div>
          <div>
            <blockquote>
              &ldquo;Melinda is innovative, proactive, and strategic.  She brings well-researched and creative ideas to the table and gets customers excited and potential customers interested.&rdquo;
              <footer>
                — <cite className="company">Rumfish Grille</cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
      <div className={style.chat}>
        <h3>Let's Chat!</h3>
        <Link to="/contact" className={style.buttonAlt}>
          Contact Us Today
        </Link>
      </div>
    </MainLayout>
  )
}

export default IndexPage
